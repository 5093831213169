import { ref, computed } from 'vue';
import type { Ref } from 'vue';

interface UseSliderProps {
  sliderContainer: Ref<HTMLElement | null>;
}

interface UseSliderReturns {
  currentTranslateX: Ref<number>;
  handleMouseDown: (event: MouseEvent | TouchEvent) => void;
  handleMouseMove: (event: MouseEvent | TouchEvent) => void;
  handleMouseUp: () => void;
  isMoved: Ref<boolean>;
}

export const useSlider = ({
  sliderContainer,
}: UseSliderProps): UseSliderReturns => {
  const startX = ref(0);
  const currentTranslateX = ref(0);
  const lastTranslateX = ref(0);
  const isDragging = ref(false);
  const isMoved = ref(false);

  const maxTranslateX = computed(() => {
    const containerWidth = sliderContainer.value
      ? sliderContainer.value.offsetWidth
      : 0;
    const contentWidth = sliderContainer.value
      ? sliderContainer.value.scrollWidth
      : 0;
    return -(contentWidth - containerWidth);
  });

  const handleMouseDown = (event: MouseEvent | TouchEvent) => {
    isDragging.value = true;
    startX.value =
      'touches' in event ? event.touches[0].clientX : event.clientX;
  };

  const handleMouseMove = (event: MouseEvent | TouchEvent) => {
    if (!isDragging.value) return;
    const clientX =
      'touches' in event ? event.touches[0].clientX : event.clientX;
    const diffX = clientX - startX.value;
    let newTranslateX = lastTranslateX.value + diffX;

    newTranslateX = Math.min(newTranslateX, 0);
    newTranslateX = Math.max(newTranslateX, maxTranslateX.value);

    currentTranslateX.value = newTranslateX;

    if (Math.abs(diffX) > 10) isMoved.value = true;
  };

  const handleMouseUp = () => {
    isDragging.value = false;
    lastTranslateX.value = currentTranslateX.value;
  };

  return {
    currentTranslateX,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    isMoved,
  };
}
